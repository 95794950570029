label.input.error-outline {
    outline-color: var(--color-notify-error);
  }
  
  
  
  
  input {
    background-color: transparent;
    color: rgba(var(--color-text-primary), var(--color-text-primary-alpha));
    border: none;
    padding: 0px var(--space-text-offset);
  }
  
  label.container-outline, label.container-fill, label.container-default {
    padding: var(--space-v-lg-int) var(--space-h-lg-int);
  }
  label.container-outline:hover, label.container-fill:hover, label.container-default:hover {
    background-color: rgba(var(--color-surface-hover), var(--color-surface-hover-alpha));
    cursor: text;
  }
  label.container-outline:focus-within, label.container-fill:focus-within, label.container-default:focus-within {
    -webkit-box-shadow: 0 0 0 2px rgba(var(--color-primary), var(--color-primary-alpha));
            box-shadow: 0 0 0 2px rgba(var(--color-primary), var(--color-primary-alpha));
    outline-style: none;
    background-color: transparent;
  }
  label.container-outline:focus-within:hover, label.container-fill:focus-within:hover, label.container-default:focus-within:hover {
    background-color: transparent;
  }
  
  
  input[type=text], input[type=email], input[type=password] {
    width: 100%;
    font: var(--font-body);
  }
  
  input:focus {
    outline-style: none;
  }
  
  .input-color .color-well {
    border-radius: var(--radius-small-control);
    width: var(--size-small-controls);
    height: 24px;
  }
  
  .input, .input-color {
    padding: var(--space-v-lg-int) var(--space-h-lg-int);
  }
  
  .input:has(input:-webkit-autofill) {
    background-color: rgba(var(--color-primary), 0.05);
  }
  .input:has(input:-webkit-autofill):hover {
    background-color: rgba(var(--color-primary), 0.05);
  }
  
  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgba(var(--color-primary), 0.05) inset, 0 0 0 30px rgb(var(--color-background-elevated)) inset !important;
  }