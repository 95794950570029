.form-sheet {
    display: none;
    visibility: hidden;
}

.form-sheet.active {
    display: flex;
    visibility: visible;
}

.form-sheet .overlay {
    background: rgba(000,000, 000, .4);
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    cursor: pointer;
    position: fixed;
}

.form-sheet .sheet {
    box-shadow: 0px 8px 24px -4px rgba(000,000, 000, .4);
    position: fixed;
    top: 0px;
}