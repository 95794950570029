.sheet {
    background: rgb(var(--color-background-elevated));
    border-radius: var(--radius-page);
    overflow: hidden;
}

.sheet.form {
    width: 100%;
    max-width: 640px;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    top: 50%;
    z-index: 101;
}