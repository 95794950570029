.navbar {
    position: fixed;
    width: 100%;
    background-color: transparent;
    backdrop-filter: none;
    z-index: 100;
}

.navbar.sticky {
    backdrop-filter: saturate(180%) blur(20px);
}

nav>div {
    background-color: transparent;
}

/* .navTabs {
    display: flex;
    @media (max-width: 530px) {
        display: none;
    }
}

.navHamburger {
    display: none;
    @media (max-width: 530px) {
        display: flex;
    }
} */